import { Icon } from '@/models/interfaces'
import { DataTableHeader } from 'vuetify'
import { Process } from '@/entities/settings'

export enum CellType {
  text = 'text-cell',
  status = 'status-cell',
  action = 'action-cell',
  linked = 'linked-cell',
  expand = 'expand-cell',
  price = 'price-cell',
  alert = 'alert-cell',
  icon = 'icon-cell',
  stock = 'stock-cell',
  progress = 'progress-cell',
  rating = 'rating-cell',
  address = 'address-cell',
  cellList = 'list-component-cell',
  date = 'date-cell',
  inspectionSummary = 'inspection-summary-cell',
  photo = 'photo-cell',
  letterApproval = 'letter-approval-cell',
  backupPayment = 'backup-payment-cell',
  chips = 'chips-cell',
  pdfReserve = 'pdf-reserve-cell',
  pdfSale = 'pdf-sale-cell',
  pdfResponsability = 'pdf-responsability-cell',
  pdfPurchase = 'pdf-purchase-cell',
  pdfWarranty = 'pdf-warranty-cell',
  pdfConsignment = 'pdf-consignment-cell',
  pdfConsignmentWithdrawal = 'pdf-consignment-withdrawal-cell',
  alertReserveSell = 'alert-reserve-sell-cell',
  alertConsignment = 'alert-consignment-cell',
  statusConsignment = 'consignment-cell',
  expirationConsignment = 'consignment-expiration-cell',
  stockButton = 'stock-button-cell',
  dailyAccountAlert = 'daily-account-alert-cell',
  stockDaily = 'stock-daily-cell',
  stockLeadReceived = 'stock-lead-received-cell',
  purchaseStatusCell = 'purchase-status-cell',
  stockCharacteristics = 'stock-characteristics-cell',
  leadActivityHistory = 'lead-activity-history-cell',
  redirectDt = 'redirect-dt-cell',
  totalProfit = 'total-profit-cell',
  statusAppraisalCell = 'status-appraisal-cell',
  StockDailyProgress = 'stock-daily-progress-cell',
  retirementConsignmentCell = 'retirement-consignment-cell',
  removeStockPublicationCell = 'remove-stock-publication-cell',
  alertFinancier = 'alert-financier-cell',
  alertStock = 'alert-stock-cell',
  alertAppraisal = 'alert-appraisal-cell',
  voidedPayment = 'voided-payment-cell',
  backupExpenseCell = 'backup-expense-cell',
  stockDetailStatusCell = 'stock-detail-status-cell',
}

// ---------------------------------------------------------------------------------------------------------------------
export interface StockDetailStatusCellHeader extends CellHeader {
  type: CellType.stockDetailStatusCell
  options?: LinkedCellOptions
}

export type StockDetailStatusCellDefinition = Omit<StockDetailStatusCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface BackupExpenseCellHeader extends CellHeader {
  type: CellType.backupExpenseCell
  options?: {
    name: string
  }
}

export type BackupExpenseCellDefinition = Omit<BackupExpenseCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface VoidedPaymentCellHeader extends CellHeader {
  type: CellType.voidedPayment
}

export type VoidedPaymentCellDefinition = Omit<VoidedPaymentCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface AlertAppraisalCellHeader extends CellHeader {
  type: CellType.alertAppraisal
  options?: BasicCellOptions
}

export type AlertAppraisalCellDefinition = Omit<AlertAppraisalCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface AlertFinancierCellHeader extends CellHeader {
  type: CellType.alertFinancier
  options?: BasicCellOptions
}

export type AlertFinancierCellDefinition = Omit<AlertFinancierCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface AlertStockCellHeader extends CellHeader {
  type: CellType.alertStock
  options?: BasicCellOptions
}

export type AlertStockCellDefinition = Omit<AlertStockCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface RemoveStockPublicationCellHeader extends CellHeader {
  type: CellType.removeStockPublicationCell
}

export type RemoveStockPublicationCellDefinition = Omit<RemoveStockPublicationCellHeader, 'text' | 'value'>
// ---------------------------------------------------------------------------------------------------------------------
export interface RetirementConsignmentCellHeader extends CellHeader {
  type: CellType.retirementConsignmentCell
  options?: LinkedCellOptions
}

export type RetirementConsignmentCellDefinition = Omit<RetirementConsignmentCellHeader, 'text' | 'value'>
// ---------------------------------------------------------------------------------------------------------------------
export interface StockDailyProgressCellHeader extends CellHeader {
  type: CellType.StockDailyProgress
}

export type StockDailyProgressCellDefinition = Omit<StockDailyProgressCellHeader, 'text' | 'value'>
// ---------------------------------------------------------------------------------------------------------------------
export interface StatusAppraisalCellHeader extends CellHeader {
  type: CellType.statusAppraisalCell
  options?: StatusCellOptions
}

export type StatusAppraisalCellDefinition = Omit<StatusAppraisalCellHeader, 'text' | 'value'>
// ---------------------------------------------------------------------------------------------------------------------
export interface TotalProfitCellHeader extends CellHeader {
  type: CellType.totalProfit
}

export type TotalProfitCellDefinition = Omit<TotalProfitCellHeader, 'text' | 'value'>
// ---------------------------------------------------------------------------------------------------------------------
export interface RedirectDtCellHeader extends CellHeader {
  type: CellType.redirectDt
  options?: LinkedCellOptions
}

export type RedirectDtCellDefinition = Omit<RedirectDtCellHeader, 'text' | 'value'>
// ---------------------------------------------------------------------------------------------------------------------
export interface LeadActivityHistoryCellHeader extends CellHeader {
  type: CellType.leadActivityHistory
  options?: LinkedCellOptions
}

export type LeadActivityHistoryCellDefinition = Omit<LeadActivityHistoryCellHeader, 'text' | 'value'>
// ---------------------------------------------------------------------------------------------------------------------
export interface StockCharacteristicsCellHeader extends CellHeader {
  type: CellType.stockCharacteristics
  options?: BasicCellOptions
}

export type StockCharacteristicsCellDefinition = Omit<StockCharacteristicsCellHeader, 'text' | 'value'>
// ---------------------------------------------------------------------------------------------------------------------
export interface PurchaseStatusCellHeader extends CellHeader {
  type: CellType.purchaseStatusCell
  options?: StatusCellOptions
}

export type PurchaseStatusCellDefinition = Omit<PurchaseStatusCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface AlertConsignmentCellHeader extends CellHeader {
  type: CellType.alertConsignment
  options?: BasicCellOptions
}

export type AlertConsignmentCellDefinition = Omit<AlertConsignmentCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface StockLeadReceivedCellHeader extends CellHeader {
  type: CellType.stockLeadReceived
  options?: LinkedCellOptions
}

export type StockLeadReceivedCellDefinition = Omit<StockLeadReceivedCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface StockDailyCellHeader extends CellHeader {
  type: CellType.stockDaily
  options?: LinkedCellOptions
}

export type StockDailyCellDefinition = Omit<StockDailyCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface DailyAccountAlertCellHeader extends CellHeader {
  type: CellType.dailyAccountAlert
  options?: BasicCellOptions
}

export type DailyAccountAlertCellDefinition = Omit<DailyAccountAlertCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface StockButtonCellHeader extends CellHeader {
  type: CellType.stockButton
  options?: LinkedCellOptions
}

export type StockButtonCellDefinition = Omit<StockButtonCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface ConsignmentExpirationCellHeader extends CellHeader {
  type: CellType.expirationConsignment
  options?: StatusCellOptions
}

export type ConsignmentExpirationCellDefinition = Omit<ConsignmentExpirationCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface PdfConsignmentWithdrawalCellHeader extends CellHeader {
  type: CellType.pdfConsignmentWithdrawal
  options?: LinkedCellOptions
}

export type PdfConsignmentWithdrawalCellDefinition = Omit<PdfConsignmentWithdrawalCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface PdfConsignmentCellHeader extends CellHeader {
  type: CellType.pdfConsignment
  options?: LinkedCellOptions
}

export type PdfConsignmentCellDefinition = Omit<PdfConsignmentCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface ConsignmentStatusCellHeader extends CellHeader {
  type: CellType.statusConsignment
  options?: StatusCellOptions
}

export type ConsignmentStatusCellDefinition = Omit<ConsignmentStatusCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface PdfPurchaseCellHeader extends CellHeader {
  type: CellType.pdfPurchase
  options?: LinkedCellOptions
}

export type PdfPurchaseCellDefinition = Omit<PdfPurchaseCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface PdfResponsabilityCellHeader extends CellHeader {
  type: CellType.pdfResponsability
  options?: LinkedCellOptions
}

export type PdfResponsabilityCellDefinition = Omit<PdfResponsabilityCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface PdfWarrantyCellHeader extends CellHeader {
  type: CellType.pdfWarranty
  options?: LinkedCellOptions
}

export type PdfWarrantyCellDefinition = Omit<PdfWarrantyCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface PdfSaleCellHeader extends CellHeader {
  type: CellType.pdfSale
  options?: LinkedCellOptions
}

export type PdfSaleCellDefinition = Omit<PdfSaleCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface AlertReserveSellCellHeader extends CellHeader {
  type: CellType.alertReserveSell
  options?: BasicCellOptions
}

export type AlertReserveSellCellDefinition = Omit<AlertReserveSellCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface PdfReserveCellHeader extends CellHeader {
  type: CellType.pdfReserve
  options?: LinkedCellOptions
}

export type PdfReserveCellDefinition = Omit<PdfReserveCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface ChipCellHeader extends CellHeader {
  type: CellType.chips
}

export type ChipCellDefinition = Omit<ChipCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface InspectionSummaryCellHeader extends CellHeader {
  type: CellType.inspectionSummary
}

export type InspectionSummaryCellDefinition = Omit<InspectionSummaryCellHeader, 'text' | 'value'>

// ----------------------------------------------------------------------------------------------------------------------
export interface BackupPaymentCellHeader extends CellHeader {
  type: CellType.backupPayment
  options?: {
    name: string
  }
}

export type BackupPaymentCellDefinition = Omit<BackupPaymentCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface LetterApprovalCellHeader extends CellHeader {
  type: CellType.letterApproval
}

export type LetterApprovalCellDefinition = Omit<LetterApprovalCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface PhotoCellHeader extends CellHeader {
  type: CellType.photo
}

export type PhotoCellDefinition = Omit<PhotoCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface RatingCellHeader extends CellHeader {
  type: CellType.rating
}

export type RatingCellDefinition = Omit<RatingCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface AddressCellHeader extends CellHeader {
  type: CellType.address
}

export type AddressCellDefinition = Omit<AddressCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface ProgressCellHeader extends CellHeader {
  type: CellType.progress
  options?: BasicCellOptions
}

export type ProgressCellDefinition = Omit<ProgressCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------

export interface StockCellHeader extends CellHeader {
  type: CellType.stock
  options: StatusCellOptions
}

export type StockCellDefinition = Omit<StockCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------

export interface IconCellHeader extends CellHeader {
  type: CellType.icon
}

export type IconCellDefinition = Omit<IconCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface AlertCellHeader extends CellHeader {
  type: CellType.alert
  options?: BasicCellOptions
}

export type AlertCellDefinition = Omit<AlertCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface StatusCellOptions {
  set: Record<string, Icon>
  allowCreate?: boolean
  showDetails?: boolean
}

export interface StatusCellHeader extends CellHeader {
  type: CellType.status
  options?: StatusCellOptions
}

export type StatusCellDefinition = Omit<StatusCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------

export interface BasicCellOptions {
  tooltip?: boolean
  bold?: boolean
  icon?: string
  oppositeIcon?: string
  showIcon?: boolean
  supervisor?: boolean
}

export interface PriceCellHeader extends CellHeader {
  type: CellType.price
  options?: BasicCellOptions
}

export type PriceCellDefinition = Omit<PriceCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface PaddingOption {
  py?: number
  px?: number
  pt?: number
  pb?: number
  pl?: number
  pr?: number
}

export interface ListComponentCellOptions {
  component: CellType.status | CellType.price | CellType.alert | CellType.rating | CellType.linked | CellType.action | CellType.text
  cols?: number
  row?: PaddingOption
  col?: PaddingOption
  options?: StatusCellOptions | BasicCellOptions | LinkedCellOptions | PriceCellOptions | TextCellOptions
  trigger?: (item: any) => void
}

export interface ListComponentCellHeader extends CellHeader {
  type: CellType.cellList
  options?: ListComponentCellOptions
}

export type ListComponentCellDefinition = Omit<ListComponentCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface PriceCellOptions {
  colTitle?: number
  colValue?: number
  valueCenter?: boolean
}

export interface LinkedCellOptions {
  path?: string
  tooltip?: string
  disabled?: boolean
  trigger?: (item: any) => void
  allowCreate?: boolean
  displayPhone?: boolean
  disabledActionMissingProperty?: {
    key: string
  }
  action?: {
    trigger?: (item: any, process: Process, system: string) => void
    color?: string
    icon?: string
    only?: boolean
    always?: boolean
    iconColor?: string
    messaging?: boolean
  }
  disabledMissingProperty?: Array<string>
  query?: Record<string, any>
  queryValidationString?: string
}

export interface LinkedCellHeader extends CellHeader {
  type: CellType.linked
  options?: LinkedCellOptions
}

export type LinkedCellDefinition = Omit<LinkedCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface ExpandCellOptions {
  expandOff?: boolean
  removeOff?: boolean
  editOff?: boolean
  show?: boolean
  titleTooltip?: string
  action?: {
    [key: string]: (item: any) => void
  }
}

export interface ExpandCellHeader extends CellHeader {
  type: CellType.expand
  options?: ExpandCellOptions
}

export type ExpandCellDefinition = Omit<ExpandCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------
export interface TextCellOptions {
  align: string
}

export interface TextCellHeader extends CellHeader {
  type: CellType.text | CellType.date
  options?: TextCellOptions
}

export type TextCellDefinition = Omit<TextCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------

export interface ActionCellHeader extends CellHeader {
  type: CellType.action
  options?: LinkedCellOptions
}

export type ActionCellDefinition = Omit<ActionCellHeader, 'text' | 'value'>

// ---------------------------------------------------------------------------------------------------------------------

interface CellHeader extends DataTableHeader {
  type: CellType
  options?: Record<string, any>
}

export type TableCellHeader =
  LinkedCellHeader
  | StatusCellHeader
  | TextCellHeader
  | ExpandCellHeader
  | ActionCellHeader
  | PriceCellHeader
  | ListComponentCellHeader
  | AlertCellHeader
  | IconCellHeader
  | StockCellHeader
  | ProgressCellHeader
  | AddressCellHeader
  | RatingCellHeader
  | InspectionSummaryCellHeader
  | PhotoCellHeader
  | LetterApprovalCellHeader
  | BackupPaymentCellHeader
  | ChipCellHeader
  | PdfReserveCellHeader
  | AlertReserveSellCellHeader
  | PdfSaleCellHeader
  | PdfResponsabilityCellHeader
  | PdfPurchaseCellHeader
  | PdfWarrantyCellHeader
  | PdfConsignmentCellHeader
  | PdfConsignmentWithdrawalCellHeader
  | ConsignmentStatusCellHeader
  | ConsignmentExpirationCellHeader
  | StockButtonCellHeader
  | DailyAccountAlertCellHeader
  | StockDailyCellHeader
  | StockLeadReceivedCellHeader
  | AlertConsignmentCellHeader
  | PurchaseStatusCellHeader
  | StockCharacteristicsCellHeader
  | LeadActivityHistoryCellHeader
  | RedirectDtCellHeader
  | TotalProfitCellHeader
  | StatusAppraisalCellHeader
  | StockDailyProgressCellHeader
  | RetirementConsignmentCellHeader
  | RemoveStockPublicationCellHeader
  | AlertFinancierCellHeader
  | AlertStockCellHeader
  | AlertAppraisalCellHeader
  | VoidedPaymentCellHeader
  | BackupExpenseCellHeader
  | StockDetailStatusCellHeader
