import {
  ActionCellDefinition,
  AddressCellDefinition,
  AlertCellDefinition,
  BackupExpenseCellDefinition,
  BackupPaymentCellDefinition,
  CellType,
  ChipCellDefinition,
  ExpandCellDefinition,
  InspectionSummaryCellDefinition,
  ListComponentCellDefinition,
  PdfReserveCellDefinition,
  PriceCellDefinition,
  RatingCellDefinition,
  TextCellDefinition,
} from '@/components/dataTables/cell'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Person } from '@/entities/persons'

export const IdHeader: ExpandCellDefinition = {
  align: 'center',
  width: 160,
  filterable: true,
  sortable: true,
  type: CellType.expand,
  options: {
    action: {
      edit: Helpers.linkedModel,
      remove: Helpers.removeLinkedModel,
    },
  },
}

export const TextHeader: TextCellDefinition = {
  align: 'center',
  filterable: false,
  width: 90,
  type: CellType.text,
}

export const DateHeader: TextCellDefinition = {
  align: 'center',
  filterable: false,
  width: 150,
  sortable: false,
  type: CellType.date,
}

export const CreateLinkedRecordHeader: ActionCellDefinition = {
  align: 'center',
  sortable: false,
  width: 100,
  filterable: false,
  type: CellType.action,
}

export const AddressHeader: AddressCellDefinition = {
  align: 'center',
  filterable: false,
  width: 90,
  sortable: false,
  type: CellType.address,
}

export const PriceHeader: PriceCellDefinition = {
  align: 'center',
  filterable: false,
  width: 160,
  sortable: true,
  type: CellType.price,
  options: {
    tooltip: false,
    icon: '',
  },
}

export const RatingHeader: RatingCellDefinition = {
  align: 'center',
  filterable: false,
  width: 250,
  sortable: false,
  type: CellType.rating,
}

export const WhatsappHeader: ActionCellDefinition = {
  align: 'center',
  sortable: false,
  width: 85,
  filterable: false,
  type: CellType.action,
  options: {
    action: {
      always: true,
      color: 'primary',
      icon: 'mdi-whatsapp',
      only: true,
      trigger: (item: Person) => Helpers.contact(item, item.message),
    },
    disabledMissingProperty: ['phone'],
  },
}

export const ActionHeader: ActionCellDefinition = {
  align: 'center',
  sortable: false,
  width: 85,
  filterable: true,
  type: CellType.action,
}

export const AlertsHeader: ListComponentCellDefinition = {
  align: 'center',
  filterable: false,
  width: 110,
  sortable: false,
  type: CellType.cellList,
  options: {
    cols: 4,
    component: CellType.alert,
  },
}

export const ReportHeader: ListComponentCellDefinition = {
  align: 'center',
  filterable: false,
  width: 180,
  sortable: false,
  type: CellType.cellList,
  options: {
    component: CellType.price,
    options: {
      colTitle: 9,
      colValue: 3,
      valueCenter: true,
    },
  },
}

export const AlertHeader: AlertCellDefinition = {
  align: 'center',
  filterable: false,
  width: 110,
  sortable: false,
  type: CellType.alert,
}

export const ListActionHeader: ListComponentCellDefinition = {
  align: 'center',
  width: 160,
  filterable: true,
  type: CellType.cellList,
}

export const InspectionSummary: InspectionSummaryCellDefinition = {
  align: 'center',
  width: 160,
  filterable: true,
  type: CellType.inspectionSummary,
}

export const BackupPaymentHeader: BackupPaymentCellDefinition = {
  align: 'center',
  width: 160,
  filterable: true,
  type: CellType.backupPayment,
}

export const BackupExpenseHeader: BackupExpenseCellDefinition = {
  align: 'center',
  width: 160,
  filterable: true,
  type: CellType.backupExpenseCell,
}

export const ChipCellHeader: ChipCellDefinition = {
  align: 'center',
  filterable: false,
  width: 200,
  sortable: false,
  type: CellType.chips,
}

export const PdfReserveCellHeader: PdfReserveCellDefinition = {
  align: 'center',
  filterable: false,
  width: 150,
  sortable: false,
  type: CellType.pdfReserve,
  options: {
    tooltip: 'Abrir carta de reserva',
    disabled: true,
    action: {
      color: 'blue',
      icon: 'mdi-email',
      only: true,
    },
  },
}
