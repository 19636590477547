import { ResourceHeaders } from '..'
import {
  AlertConsignmentHeader,
  AppraisalStatusHeader,
  BackupExpenseHeader,
  ContactHeader,
  DateHeader,
  IdHeader,
  StockDetailsStatusHeader,
  TextHeader,
  VehicleHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Process } from '@/entities/settings'
import { Lead } from '@/entities/crm'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
    width: 130,
  },
  { ...DateHeader, text: 'Fecha de solicitud', value: 'created', sortable: false },
  {
    ...AlertConsignmentHeader, text: 'Alerta', value: '@',
  },
  { ...VehicleHeader, text: 'Vehículo', value: 'deal.auto' },
  { ...TextHeader, text: 'Tipo de gasto', value: 'expenseType', width: 120 },
  { ...TextHeader, text: 'Monto solicitado', value: 'amountFixed', width: 120 },
  { ...TextHeader, text: 'Forma de pago', value: 'type.description', width: 120 },
  { ...TextHeader, text: 'Beneficiario', value: 'paymentRecipient.person.shortName', width: 120 },
  { ...TextHeader, text: 'Rut', value: 'paymentRecipient.person.uid', width: 120 },
  { ...TextHeader, text: 'Cuenta', value: 'accountInfo', width: 200 },
  { ...AppraisalStatusHeader, text: 'Estado', value: '@', width: 120 },
  { ...StockDetailsStatusHeader, text: 'Estado de stock', value: '@' },
  {
    ...ContactHeader,
    text: 'Ejecutivo',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'executive_expense',
      },
      path: 'deal.lead.executive.person.name',
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'executive_expense', process, system),
      },
      disabledMissingProperty: ['deal.lead.executive.phone'],
    },
  },
  {
    ...ContactHeader,
    text: 'Validador',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'validator',
      },
      path: 'validator.person.name',
      action: {
        always: true,
        color: 'primary',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'validator', process, system),
      },
      disabledMissingProperty: ['validator.phone'],
    },
  },
  { ...TextHeader, text: 'Pagador', value: 'responsible.person.shortName', width: 120 },
  {
    ...BackupExpenseHeader,
    text: 'Respaldo',
    value: '@',
    width: 60,
    sortable: false,
    options: {
      name: 'expense_payment',
    },
  },

]

export default function (): ResourceHeaders {
  return headers
}
